import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Layout from "../components/layout"
import { Title } from "../components/Title"
import { ContentWrapper } from "../components/ContentWrapper"
import { SloganLine, SloganText } from "../components/Slogan"

const OffertPage = ({ data }) => (
  <Layout logo="black">
    <Seo
      title={data.datoCmsOfferPage.seo?.title}
      description={data.datoCmsOfferPage.seo?.description}
      meta={data.datoCmsOfferPage.tags}
    />
    <StyledGrid>
      <StyledTitle>{data.datoCmsOfferPage.title}</StyledTitle>
      <StyledSloganLine direction="left" />
      <StyledSloganText direction="left">
        {data.datoCmsOfferPage.slogan}
      </StyledSloganText>
      <StyledContentWrapper
        dangerouslySetInnerHTML={{ __html: data.datoCmsOfferPage.content }}
      />
      <StyledGatsbyImage image={getImage(data.datoCmsOfferPage.image)} alt="" />
    </StyledGrid>
  </Layout>
)

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 45px 20px 1fr 30px;
  grid-template-rows: repeat(4, auto);

  @media (min-width: 992px) {
    grid-template-columns: 150px 130px 1fr 285px;
  }
`

const StyledTitle = styled(Title)`
  grid-row: 1;
  grid-column: 1/5;

  margin-bottom: 0;

  @media (min-width: 992px) {
    margin-bottom: 110px;
  }
`

const StyledSloganRow = css`
  grid-row: 2;

  margin-top: 60px;
`

const StyledSloganText = styled(SloganText)`
  ${StyledSloganRow}

  grid-column: 3;
  margin-left: 25px;

  @media (min-width: 992px) {
    grid-column: 2/5;

    margin-left: 40px;
    max-width: 750px;
  }
`

const StyledSloganLine = styled(SloganLine)`
  ${StyledSloganRow}

  grid-column: 1/3;

  @media (min-width: 992px) {
    grid-column: 1;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  grid-row: 3;
  grid-column: 2/4;

  margin-top: 70px;

  @media (min-width: 992px) {
    grid-column: 3;

    margin-bottom: 150px;
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  grid-row: 4;
  grid-column: 1/5;

  margin-top: 75px;
`

export const query = graphql`
  query {
    datoCmsOfferPage {
      content
      slogan
      title
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      seoMetaTags {
        tags
      }
      seo {
        title
        description
      }
    }
  }
`

export default OffertPage
