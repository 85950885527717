import styled from 'styled-components';

export const Title = styled.h1`
  font-weight: 100;
  text-align: center;

  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;

  @media (min-width: 992px) {
    font-size: 70px;
  }
`;
