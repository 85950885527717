import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"

const StyledTextPrivate = styled.div`
  text-align: ${props => props.direction};
  font-size: 20px;

  @media (min-width: 600px) {
    font-size: 35px;
  }
  @media (min-width: 992px) {
    font-size: 50px;
  }
`

const StyledLinePrivate = styled.hr`
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 2px solid black;
  margin-right: ${props =>
    props.direction === "right" ? 0 : "auto"} !important;
  margin-left: ${props => (props.direction === "left" ? 0 : "auto")} !important;
  width: ${props => (props.inView ? "100%" : 0)};
  transition: ${props => (props.animate !== false ? "all 900ms" : null)};

  transform: translateY(12px);

  @media (min-width: 600px) {
    transform: translateY(25px);
    border-top: 3px solid black;
  }

  @media (min-width: 992px) {
    transform: translateY(30px);
    border-top: 4px solid black;
  }
`

export const SloganText = ({ className, children, direction }) => (
  <StyledTextPrivate
    className={"slogan-text" + className}
    direction={direction}
  >
    {children}
  </StyledTextPrivate>
)

export const SloganLine = ({ className, animate, direction }) => {
  const { ref, inView } = useInView()
  const [wasInView, setWasInView] = useState(false)

  useEffect(() => {
    if (!wasInView && inView) {
      setWasInView(true)
    }
  }, [wasInView, inView])

  return (
    <StyledLinePrivate
      ref={ref}
      className={"slogan-line" + className}
      animate={animate}
      direction={direction}
      inView={wasInView}
    />
  )
}
